<template>
  <div>
    <div class="loading-spinner" v-if="show">
      <div class="loading-spinner-div" style="margin-top:auto;height:100%">
        <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" :color="$root.colorLoading"/>
      </div>
    </div>
    <b-card>
      <div class="d-flex justify-content-between  pb-3">
        <h5 class="fontWeightBold">{{ variety.fullTitle }}</h5>
        <div class="d-flex">
          <b-button variant="success" @click="incrementModal">
            <i class="fas fa-plus"></i>
          </b-button>
          <b-button variant="danger" class="mr-1" @click="decrementModal">
            <i class="fas fa-minus"></i>
          </b-button>
        </div>
      </div>
      <div v-if="items.data">
        <b-table responsive striped outlined hover :fields="fields" :items="items.data.data">
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | persianDate }}
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="items.data.data == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
        <pagination :limit="2" v-if="items.data" :data="items.data" @pagination-change-page="loadItems">
        </pagination>
      </div>
    </b-card>
    <b-modal id="increment-modal" hide-footer :title="' اضافه کردن به انبار ' + ' ' + variety.fullTitle  ">
      <form @submit.prevent='incrementItem()'>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="quantity">تعداد <span class="text-danger">*</span></label>
            <span>موجودی انبار: {{ variety.in_stock ? variety.in_stock : 0 }}</span>
          </div>
          <b-form-input id="quantity" v-model="increment"></b-form-input>
        </b-form-group>
        <!-- <b-form-group >
            <label for="text">توضیحات <span class="text-danger">*</span></label>
            <b-form-textarea id="text" v-model="text"></b-form-textarea>
        </b-form-group> -->
        <div class="text-center">
          <button class="btn btn-success" type="submit" :disabled="disabled">ثبت</button>
        </div>
      </form>
    </b-modal>
    <b-modal id="decrement-modal" hide-footer :title="'کم کردن از انبار ' + ' ' + variety.fullTitle">
      <form @submit.prevent='decrementItem()'>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="quantity">تعداد <span class="text-danger">*</span></label>
            <span>موجودی انبار: {{ variety.in_stock ? variety.in_stock : 0 }}</span>
          </div>
          <b-form-input id="quantity" v-model="decrement"></b-form-input>
        </b-form-group>
        <!-- <b-form-group >
            <label for="text">توضیحات <span class="text-danger">*</span></label>
            <b-form-textarea id="text" v-model="text"></b-form-textarea>
        </b-form-group> -->
        <div class="text-center">
          <button class="btn btn-success" type="submit" :disabled="disabled">ثبت</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import mixins from '../mixins/mixins'

export default {
  mixins: [mixins],
  data() {
    return {
      url: '/api/seller/store',
      variety: {},
      items: [],
      fields: [{
        key: 'row_number',
        label: 'ردیف'
      },
        {
          key: 'quantity',
          label: 'تعداد'
        },
        {
          key: 'description',
          label: 'توضیح'
        },
        {
          key: 'created_at',
          label: 'تاریخ'
        },
      ],
      increment: 0,
      decrement: 0,
      text: '',
      disabled: false,
      show: false,
      title: 'کالا'
    }
  },
  mounted() {
    document.title = this.title
    if (this.$route.params.id) {
      // this.variety = {
      //         id: 1,
      //         title: 'خودکار-آبی',
      //         price: 20000,
      //         quantity: 30,
      //         quantity_sale: 10,
      //         status: 'available',
      //         discount: 2000,
      //     },
      this.show = true

      this.$axios.get(this.$root.baseUrl + '/api/seller/varieties/' + this.$route.params.id)
          .then(response => {
            this.variety = response.data.data
            this.title = this.variety.fullTitle
            this.show = false
            document.title = this.title
          })
      this.loadItems()
    }
  },
  methods: {
    loadItems(page = 1) {
      this.show = true
      this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page, {
        params: {
          search1: this.$route.params.id,
          searchBy1: 'variety_id'
        }
      }).then(response => {
        this.items = response.data
        this.show = false
      })
    },
    incrementModal() {
      this.increment = 0
      this.text = ''
      this.$root.$emit('bv::show::modal', 'increment-modal')
    },
    decrementModal() {
      this.decrement = 0
      this.text = ''
      this.$root.$emit('bv::show::modal', 'decrement-modal')
    },
    incrementItem() {
      this.disabled = true
      this.$axios.post(this.$root.baseUrl + `/api/seller/varieties/${this.$route.params.id}/increment`, {
        count: this.increment
      })
          .then(response => {
            this.items.data.data.map(item => item.row_number = item.row_number + 1)
            this.$root.success_notification(response.data.message)
            this.variety.in_stock = this.variety.in_stock + +this.increment
            this.$root.$emit('bv::hide::modal', 'increment-modal')
            this.items.data.data.unshift({
              row_number: 1,
              type: 'increment',
              quantity: this.increment,
              description: ' به تعداد ' + this.increment + ' توسط فروشنده به انبار اضافه شد '
            })
            this.loadItems()
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
    },
    decrementItem() {
      this.disabled = true
      this.$axios.post(this.$root.baseUrl + `/api/seller/varieties/${this.$route.params.id}/decrement`, {
        count: this.decrement
      })
          .then(response => {
            this.items.data.data.map(item => item.row_number = item.row_number + 1)
            this.$root.success_notification(response.data.message)
            this.variety.in_stock = this.variety.in_stock - +this.decrement
            this.items.data.data.unshift({
              row_number: 1,
              type: 'decrement',
              quantity: this.decrement,
              description: ' به تعداد ' + this.decrement + ' توسط فروشنده از انبار کم شد '
            })
            this.$root.$emit('bv::hide::modal', 'decrement-modal');
            this.loadItems()
            // this.loadItems()
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })

    }
  }
}
</script>
